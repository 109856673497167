<template>
  <main>
    <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
      <div class="container-fluid">
        <div class="page-header-content">
          <div class="row align-items-center justify-content-between pt-3">
            <div class="col-auto mb-3">
              <h1 class="page-header-title">
                <div class="page-header-icon">
                  <svg
                      class="feather feather-user"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      width="24"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                Datos del usuario
              </h1>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container mt-4">
      <!-- Account page navigation-->
      <div class="row">
        <!-- <div class="col-xl-4"></div> -->
        <div class="col-xl-12 row">
          <div class="col-xl-6">
            <div>
              <div class="mr-2">
                <i class="fas fa-hospital" style="font-size: 2.5rem"></i>
              </div>
            </div>
            <div>
              <span class="d-block">Eps</span>
            </div>
            <div>
              <h5>
                {{
                  usuario.nombreEps
                }}
              </h5>
            </div>
          </div>
          <div class="col-xl-3">
            <div>
              <div class="mr-2">
                <i class="fas fa-street-view" style="font-size: 2.5rem"></i>
              </div>
            </div>
            <div>
              <span class="d-block">Ubicacion</span>
            </div>
            <div>
              <h5>
                {{

                  usuario.municipio

                }}
              </h5>
            </div>
          </div>
          <div class="col-xl-3">
            <div>
              <div class="mr-2">
                <i class="fas fa-list-ol" style="font-size: 2.5rem"></i>
              </div>
            </div>
            <div>
              <span class="d-block">Nivel</span>
            </div>
            <div>
              <h5>
                {{ usuario.nivel }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-0 mb-4"/>
      <div class="row">
        <div class="col-xl-8">
          <div class="card">
            <div class="card-header">DATOS BASICOS</div>
            <div class="card-body">
              <form>
                <div class="form-group row">
                  <h6>NOMBRE :</h6>
                  <label class="small mb-1"
                  >{{

                      usuario.nombre_1

                    }}
                    {{

                      usuario.nombre_2

                    }}
                    {{

                      usuario.apellido_1

                    }}
                    {{

                      usuario.apellido_2

                    }}
                  </label>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-5 row">
                    <h6>DOCUMENTO:</h6>
                    <label class="small mb-1">{{

                        usuario.documento
                      }}</label>
                  </div>

                  <div class="form-group col-md-7 row">
                    <h6>TIPO :</h6>
                    <label class="small mb-1">
                      {{

                        usuario.tipo_documento
                            | upper
                      }}
                    </label>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12 row">
                    <h6>GENERO :</h6>
                    <label class="small mb-1">
                      {{
                        usuario.genero
                      }}</label>
                  </div>
                </div>

                <div class="form-group row">
                  <h6>FECHA DE NACIMINETO:</h6>
                  <label class="small mb-1">{{

                      usuario.fecha_nacimiento   | formatDate
                    }}</label>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6 row">
                    <h6>EDAD :</h6>
                    <label class="small mb-1">
                      {{ usuario.edad }}</label
                    >
                  </div>

                  <div class="form-group col-md-6 row">
                    <h6>ESTADO :</h6>
                    <label class="small mb-1">
                      {{
                        usuario.activo
                      }}
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <br/>

          <div class="card">
            <div class="card-header">UBICACION DEL USUARIO</div>
            <div class="card-body">
              <form>
                <div class="small font-italic text-muted mb-2">
                  <div class="form-row">
                    <div class="form-group col-md-6 row">
                      <h6>DIRECCION :</h6>
                      <label class="small mb-1">
                        {{
                          usuario.direccion
                        }}
                      </label>
                    </div>
                    <div class="form-group col-md-6 row">
                      <h6>ZONA :</h6>
                      <label class="small mb-1">
                        {{
                          usuario.zona
                        }}
                      </label>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group row col-md-6">
                      <h6>MUNICIPIO :</h6>
                      <label class="small mb-1">
                        {{

                          usuario.municipio

                        }}
                      </label>
                    </div>
                    <div class="form-group row col-md-6">
                      <h6>DEPARTAMENTO :</h6>
                      <label class="small mb-1">
                        {{
                          usuario.departamento
                        }}
                      </label>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group row col-md-6">
                      <h6>NUMERO DE CONTACTO :</h6>
                      <label class="small mb-1">
                        {{

                          usuario.telefono

                        }}
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="card">
            <div class="card-header">INFORMACION ADICIONAL</div>
            <div class="card-body text-left">
              <div class="small font-italic text-muted mb-4">
                <h6>OCUPACION :</h6>
                <p>
                  {{
                    usuario.id_profesion
                  }}
                </p>
              </div>
              <div class="small font-italic text-muted mb-2 row col-md-12">
                <h6>ESTADO CIVIL :</h6>
                <p>
                  {{
                    usuario.estado_civil
                  }}
                </p>
              </div>
              <div class="small font-italic text-muted mb-2 col-md-12 row">
                <h6>ETNIA :</h6>
                <p>
                  {{
                    usuario.etnia
                  }}
                </p>
              </div>
            </div>
          </div>

          <br/>
          <div class="card">
            <div class="card-header">INFORMACION DE LA EPS</div>
            <div class="card-body text-left">
              <div class="small font-italic text-muted">
                <h6>NOMBRE :</h6>
                <p>
                  {{
                    usuario.nombreEps
                  }}
                </p>
                <p>
                  {{
                    usuario.nombreEnlineaEps
                  }}
                </p>
              </div>
              <div class="small font-italic text-muted">
                <h6>NIT :</h6>
                <p>
                  {{ usuario.nitEps }}
                </p>
              </div>
              <div class="small font-italic text-muted">
                <h6>TELEFONO DE LA ENTIDAD :</h6>
                <p>
                  {{
                    usuario.telefonoEps
                  }}
                </p>
              </div>
            </div>
          </div>
          <br/>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Axios from "axios";
import {isEmpty} from 'lodash';

export default {
  async created() {
    try {

      // this.ver(id_usuario);
      const id_usuario = this.$route.params.id;
      this.LoaderSpinnerShow();
      const data = await this.ver(id_usuario);
      this.usuario.nombre_1 = data?.nombre_1;
      this.usuario.nombre_2 = data?.nombre_2;
      this.usuario.apellido_1 = data?.apellido_1;
      this.usuario.apellido_2 = data?.apellido_2;
      this.usuario.documento = data?.documento;
      this.usuario.fecha_nacimiento = data?.fecha_nacimiento;
      this.usuario.id_tipo_documento = data?.tipo_documento.id;
      this.usuario.genero = data?.sexo === "M" ? "MASCULINO" : "FEMENINO";
      this.usuario.direccion = data?.direccion;
      this.usuario.telefono = data?.telefono;
      this.usuario.nivel = data?.nivel;
      this.usuario.id_profesion = isEmpty(data.ocupacion) ? "" : data.ocupacion.nombre;
      this.usuario.municipio = isEmpty(data.municipio) ? "" : data.municipio.nombre;
      this.usuario.zona = isEmpty(data.zona) ? "" : data.zona.descripcion;
      this.usuario.nombreEps = isEmpty(data.eps) ? "" : data.eps.DESEPS;
      this.usuario.etnia = isEmpty(data.etnia) ? "" : data.etnia.nombre;
      this.usuario.estado_civil = isEmpty(data.estado_civil) ? "" : data.estado_civil.descripcion;
      this.usuario.departamento = isEmpty(data.municipio.departamento) ? "" : data.municipio.departamento.nombre;
      this.usuario.nombreEnlineaEps = isEmpty(data.eps) ? "" : data.eps.IDEPS;
      this.usuario.ocupacion = isEmpty(data.id_profesion) ? "" : data.ocupacion.nombre;
      this.usuario.nitEps = isEmpty(data.eps) ? "" : data.eps.NIT;
      this.usuario.telefonoEps = isEmpty(data.eps) ? "" : data.eps.TELEFONO;
      this.usuario.edad = isEmpty(data) ? "" : data?.years;
      this.LoaderSpinnerHide();
    } catch (e) {
      console.error(e);
    }
  },
  data() {
    return {

      usuario: {
        nombre_1: "",
        nombre_2: "",
        apellido_1: "",
        apellido_2: "",
        sexo: "",
        documento: "",
        tipo_documento: "",
        fecha_nacimiento: "",
        ocupacion: "",
        estado_civil: "",
        etnia: "",
        zona: "",
        direccion: "",
        municipio: "",
        telefono: "",
        nombreEps: "",
        nombreEnlineaEps: "",
        nitEps: "",
        telefonoEps: "",
        nivel: "",
        departamento: "",
        edad: "",
      },
    };
  },
  filters: {
    upper: function (value) {
      if (value !== undefined) return value.toUpperCase();
    },
  },
  methods: {

    async ver(id) {
      try {
        let url = "/api/usuarios-evt/ver/" + id;
        let response = await Axios.get(url);
        return response?.data;
      } catch (e) {
        console.error(e);
      }

    },

    // ver(id) {
    //   let url = "/api/usuarios-evt/ver/" + id;
    //   Axios.get(url).then((response) => {
    //     let user;
    //     user = response.data;
    //    Object.assign(this.usuario = user);

    //   });
    // },
  },
  computed: {},
};
</script>
